
$(function () {
    let firstTimeMenuChangeBig = true,
        firstTimeMenuChangeSmall = true;
    const $button = $('.js-btn-menu-mobile');
    const $search = $('.c-search-header');
    const $navigation = $('.c-navigation__nav');
    const $body = $('body');

    $(window).on('load resize orientationchange', function () {
        if (firstTimeMenuChangeBig && $(window).width() > 991) {
            firstTimeMenuChangeSmall = true;
            firstTimeMenuChangeBig = false;
            $('#menu').removeAttr('aria-hidden').show().off('keydown.aria.btnMenuMobile');
            $button.attr('aria-expanded', 'true');
            $body.off('click.aria.btnMenuMobile');
            $navigation.appendTo('.js-nav-desktop');
            $search.appendTo('.js-search-desktop');
            $body.removeClass('u-overflow-hidden');
        } else if (firstTimeMenuChangeSmall && $(window).width() <= 991) {
            firstTimeMenuChangeSmall = false;
            firstTimeMenuChangeBig = true;
            $('.js-btn-menu-mobile[aria-expanded="true"]').trigger('click', [true]);
            $navigation.appendTo('.js-nav-mobile');
            $search.appendTo('.js-search-mobile');
        }
    });

    $('.js-toggle').on('click', function (e) {
        const $button = $(this);
        const $target = $('#' + $button.attr('aria-controls'));
        const hasOverlay = $button[0].hasAttribute('data-overlay');
        if ($button.attr('aria-expanded') === 'true') {
            // on ferme
            if (window.innerWidth < 992) {
                $body.removeClass('u-overflow-hidden');
                $target.attr('aria-hidden', 'true').removeClass('is-visible').off('keydown.aria.' + $button.attr('id'));
            } else {
                $target.attr('aria-hidden', 'true').stop().slideUp().off('keydown.aria.' + $button.attr('id'));
            }
            $button.attr('aria-expanded', 'false');
            $body.off('click.aria.' + $button.attr('id'));
            if (hasOverlay === true) {
                $('.o-overlay').fadeOut('100', function () {
                    $(this).remove();
                });
            }
        } else {
            // on ouvre
            if (window.innerWidth < 992) {
                $body.addClass('u-overflow-hidden');
                $target.removeAttr('aria-hidden').addClass('is-visible');
            } else {
                $target.removeAttr('aria-hidden').stop().slideDown();
            }
            if (hasOverlay === true && window.innerWidth > 992) {
                setTimeout(function () {
                    $('<div class="o-overlay"></div>').hide().appendTo('body').fadeIn('100');
                }, 100);
            }
            $button.attr('aria-expanded', 'true');
            // on focus
            $target.focus();
            if (!$target.is(':focus')) {
                $target.attr('tabindex', '-1');
                $target.focus();
            }
            // echappe
            $target.on('keydown.aria.' + $button.attr('id'), function (ev) {
                if (ev.which === 27) {
                    $button.trigger('click', [true]);
                    $button.focus();
                }
            });
            // clic en dehors
            $body.on('click.aria.' + $button.attr('id'), function (ev, triggered) {
                if (!triggered && !$(ev.target).closest($button).length && !$(ev.target).closest($target).length) {
                    $button.trigger('click', [true]);
                }
            });
        }
        e.preventDefault();
    });

    $('#switchLng').on('change', function () {
        document.location.href = $(this).val();
    });
});
