
// CSS
require("../../css/GAB_GUIDE/scss/main.scss");

// JS - composants
require('../../js/components/skiplinks.js');
require('../../js/components/colonnage.js');
require('../../js/components/navigation.js');
require('../../js/components/back-to-top.js');
require('../../js/components/modal.js');
