$(document).ready(function () {
    const modal = $("#popin");
    const btnModal = $('.c-modal__open');
    const overlay = modal.closest(".c-modal__overlay");
    const close = modal.find(".c-modal__close");

    cmsCore.initModal(btnModal, modal, overlay, close);

    //modal recherche
    const modalSearch = $("#popinSearch");
    const btnSearch = $(".c-modal__open--search");
    const overlayLoupe = $(".c-modal__overlay--search");
    const closeLoupe = $(".c-modal__close--search");

    cmsCore.initModal(btnSearch, modalSearch, overlayLoupe, closeLoupe);

    //modal ressource obsolète
    const modalObsolete = $(".c-modal--obsolete");
    const overlayObsolete = $(".c-modal__overlay--obsolete");
    const closeObsolete = $(".c-modal__close--obsolete");
    const btnObsolete = $('.c-modal__open--obsolete');

    cmsCore.initModal(btnObsolete, modalObsolete, overlayObsolete, closeObsolete, 'obsolete');
});
