import {wrapAll} from "../modules/wrapAll";
import {nextUntil} from "../modules/nextUntil";

document.addEventListener("DOMContentLoaded", (event) => {
    const Colonization = document.querySelectorAll('[class*="PRS_WIDTH"]');
    let newDiv;

    for (let i = 0; i < Colonization.length;) {
        // Préparation des tableaux de données qui sont vidé à chaque itération
        let nodes = [];
        let nextUntilResult = [];

        // On insère le premier nœud de la sélection actuelle dans la variable nodes
        nodes.push(Colonization[i]);
        // On insère les nœuds suivants ayant la même classe dans un autre tableau de données
        nextUntilResult = nextUntil(Colonization[i], ':not([class*="PRS_WIDTH"])');
        // On combine les 2 tableaux
        nodes.push(...nextUntilResult);

        // On prépare la prochaine itération en fonction du nombre de nœuds déja insérés
        // Par exemple, si la 1ère itération englobe 3 <div>, la prochaine itération commencera à i = 3
        i += nodes.length;

        // On crée la div englobante et on lui rajoute une classe
        newDiv = document.createElement('div');
        newDiv.classList.add('has-colonnage');

        // On utilise la fonction wrapAll pour envelopper la sélection actuelle
        wrapAll(nodes, newDiv);
    }
});
