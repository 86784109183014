$(document).ready(function () {
    // Haut de page sticky
    var $hautDePage = $('.js-back-to-top');
    $hautDePage.hide();
    $(document).on('scroll', function () {
        if ($(window).scrollTop() > 1) {
            $hautDePage.show(400);
        } else {
            $hautDePage.hide(400);
        }
    });

    $hautDePage.on('click', function (e) {
        e.preventDefault();
        cmsCore.scroll($($(this).attr('href')));
    });
});
