$(document).ready(function () {
    $('.js-skiplinks').find('a')
        .on('click', function () {
            if ($(this).hasClass('trigger-click')) {
                if ($(this).data('controls')) {
                    var $e = $('#' + $(this).data('controls'));
                    if ($e.is(':visible')) {
                        $e.trigger('click', [true]);
                        return false;
                    }
                } else {
                    $(this).trigger('click', [true]);
                    return false;
                }
            }

            $($(this).attr('href')).focus();
            return false;
        });
});
